<template>
  <CCard color="light" border-color="light">
    <CRow>
      <CCol md="6" class=" text-left">
        <CCard>
          <CCardBody color="white" class="shadow-sm">
            <h2 class="text-success">
              {{ data.sendVouchersTotal }} {{ $t('item') }}
            </h2>
            <br />
            <h5 class="font-weight-normal"><em class="far fa-credit-card"></em> {{ $t('allVoucher') }}</h5>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md="6" class="text-left">
        <CCard>
          <CCardBody color="white" class="shadow-sm">
            <h2 class="text-danger">
              {{ data.isUsedTotal }} {{ $t('item') }}
            </h2>
            <br />
            <h5 class="font-weight-normal"><em class="fa fa-calendar-check-o"></em> {{ $t('used') }}</h5>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CCard color="white" style="padding:20px" class="shadow-sm">
      <CRow>
        <CCol md="4">
          <img alt="" class="img-fluid" :src="data.imgUrl" />
        </CCol>
        <CCol md="8">
          <p class="text-right">
            <a @click="$router.go(-1)">
              <CButton class="text-grey">
                <CIcon name="cil-arrow-thick-from-right" />
                {{ $t('back') }}
              </CButton>
            </a>
          </p>
          <h4 class="font-weight-normal text-left">
            {{ data.title }}
          </h4>

          <p class="text-left text-description">{{ data.description }}</p>
          <hr />

          <p class="text-left font-weight-normal text-grey">
            <em class="fas fa-coins"></em> {{ $t('voucherValue') }} :
            {{ data.value }} {{ $t('baht') }}
          </p>
          <p class="text-left text-description">{{ $t('createdAt') }} : {{ createdAt }}</p>
        </CCol>
      </CRow>
      <br />
      <CRow>
        <CCol md="12">
          <CButton v-if="data.deleted_at != null" class="btn-outline-danger btn-block" disabled>
            <CIcon name="cil-trash"></CIcon> {{ $t('cancelVoucher') }}
          </CButton>
          <CButton v-else class="btn-outline-danger btn-block" @click="popupModal = true">
            <CIcon name="cil-trash"></CIcon> {{ $t('cancelAllVoucher') }}
          </CButton>
        </CCol>
      </CRow>
    </CCard>

    <CRow>
      <CCol md="12">
        <CCardBody color="white" text-color="dark" class="text-center shadow-sm">
          <br />
          <CDataTable :items="items" :fields="fields" hover border>
            <template #isUsed="{ item }">
              <td>
                <CBadge :color="getBadge(item.isUsed)">
                  {{ item.isUsed }}
                </CBadge>
              </td>
            </template>
          </CDataTable>
          <pagination :meta_data="meta_data" v-on:next="getVoucherRedemption">
          </pagination>
        </CCardBody>
        <CModal :show.sync="popupModal" color="danger" :title="$t('cancelVoucher')" centered header="false"
          :footer="footer">
          <CRow class="justify-content-center col-md-12">
            <CCard color="white" text-color="dark" class="text-center col-md-12">
              <h4>{{ $t('wantToCancelVoucher') }}</h4>
              <h6>
                {{ $t('voucherCanNotBeRecovered') }}
                <br />
                {{ $t('voucherCanNotBeRecovered2') }}
              </h6>
            </CCard>
          </CRow>
          <template #footer-wrapper>
            <CRow class="justify-content-center">
              <CCol md="4" v-if="loadingButton === true">
                <CButton block color="danger" @click="deleteVoucher()">
                  {{ $t('confirm') }}
                </CButton>
              </CCol>
              <CCol md="4" v-else-if="loadingButton === false">
                <CButton block color="danger" disabled>
                  <CSpinner color="light" size="sm" /> {{ $t('loading') }}
                </CButton>
              </CCol>
              <CCol col="4">
                <CButton block color="light" @click="popupModal = false">
                  {{ $t('back') }}
                </CButton>
              </CCol>
            </CRow>
            <br />
            <br />
          </template>
        </CModal>
      </CCol>
    </CRow>
  </CCard>
</template>

<script>
import crm from '@/services/crm'
import moment from 'moment'
import Pagination from '@/containers/Pagination'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      data: {},
      popupModal: false,
      footer: '',
      loadingButton: true,
      detail: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
      },
    }
  },
  computed: {
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    objectId() {
      return this.$route.params.objectId
    },
    createdAt() {
      return moment(this.data.created_at).format('LLL')
    },
    fields() {
      return [
        {
          key: 'date',
          label: this.$i18n.t('receivedDate'),
          _classes: 'text-left font-weight-normal',
        },
        { key: 'member', label: this.$i18n.t('memberMenu'), _classes: 'text-right font-weight-normal' },
        {
          key: 'voucherCode',
          label: this.$i18n.t('voucherNumber'),
          _classes: 'text-right font-weight-normal',
        },
        { key: 'isUsed', label: this.$i18n.t('status'), _classes: 'text-center font-weight-normal' },
        { key: 'expireAt', label: this.$i18n.t('expiryDate'), _classes: 'text-right font-weight-normal' },
      ]
    },
    items() {
      let detail = this.detail
      let info = []
      let isUsed = ''
      let exp = ''
      let member = ''

      for (let data of detail) {
        if (data.isUsed === true) {
          isUsed = this.$i18n.t('used')
        } else if (data.isUsed === false) {
          isUsed = this.$i18n.t('availableCrm')
        }

        if (data.endAt === '') {
          exp = this.$i18n.t('noExpiryDate')
        } else {
          exp = moment(String(data.endAt)).format('DD/MM/YYYY')
        }

        if (data.member !== undefined) {
          member = data.member.name
        }

        info.push({
          date: moment(String(data.created_at)).format('DD/MM/YYYY'),
          member: member,
          voucherCode: data.voucherCode,
          isUsed: isUsed,
          expireAt: exp,
        })
      }
      return info
    },
  },
  mounted() {
    this.getVoucher()
    this.getVoucherRedemption()
  },
  methods: {
    getBadge(isUsed) {
      switch (isUsed) {
        case 'ใช้แล้ว':
        case 'used':
          return 'success'
        case 'ยังไม่ใช้':
        case 'ยังไม่ใช้งาน':
          return 'danger'
        default:
          return 'primary'
      }
    },
    getVoucher() {
      const uid = this.uid
      let objectId = this.objectId

      crm
        .get('/api/v1.0/' + uid + '/voucher/get/' + objectId)
        .then((res) => {
          this.data = res.data.data.documents
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getVoucherRedemption(page = 1) {
      const uid = this.uid
      let voucherObjectId = this.objectId
      let params = {
        page: page,
        limit: 50,
      }

      crm
        .get(
          '/api/v1.0/' +
          uid +
          '/getredemptionbyvoucherobjectId/' +
          voucherObjectId,
          { params: params }
        )
        .then((res) => {
          if (res.data.data !== undefined && res.data.data.length != 0) {
            this.detail = res.data.data
            this.meta_data.last_page = res.data.paginate.pageCount
            this.meta_data.current_page = res.data.paginate.currentPage
          } else {
            this.detail = []
          }
        })
    },
    deleteVoucher() {
      this.loadingButton = false
      const uid = this.uid
      let objectId = this.objectId

      crm
        .post('/api/v1.0/' + uid + '/voucher/delete/' + objectId)
        .then((res) => {
          if (res.data.error.code === 0) {
            //บันทึกสำเร็จ
            this.$router.go(-1)
          } else {
            //มีบางอย่างผิดพลาด
            alert(this.$i18n.t('errorAlert'))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
}
</script>
